import * as React from "react";
import type { AppProps } from "App";
import { App } from "App";
import { FrontendMonitoring } from "FrontendMonitoring";
import { Routes } from "Routes";
import "global.less";
import { createRoot } from "react-dom/client";

declare let module: __WebpackModuleApi.Module;

type AppComponent = React.ComponentType<AppProps>;

const frontendMonitoring = new FrontendMonitoring();

function render(Component: AppComponent) {
  const container = document.getElementById("app");
  if (container) {
    const root = createRoot(container);
    root.render(<Component routes={<Routes />} frontendMonitoring={frontendMonitoring} />);
  }
}

render(App);

//Do NOT move to a separate file as this wil break hot reloading
if (module.hot) {
  module.hot.accept("./App", () => {
    try {
      const NextApp = require("./App").App;
      render(NextApp);
    } catch (err) {
      console.error("Error updating app", err);
    }
  });
}
