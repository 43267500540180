import axiosClient from "../axios-client";

export enum TrialFulfillmentErrorCode {
  ActivePlatformLimitExceeded = "ActivePlatformLimitExceeded",
  HistoricalLimitExceeded = "HistoricalLimitExceeded",
}

export interface TrialFulfillmentDto {
  fulfilled: boolean;
  error?: TrialFulfillmentErrorCode;
  serverLicenses: string[];
  cloudSubscriptions: CloudTrialFulfillmentDto[];
}

export interface CloudTrialFulfillmentDto {
  serial: string;
  provisioned: boolean;
  dnsPrefix: string;
}

const BASE_URL = "bff/fulfillment";

export const getTrialFulfilled = async (trialIntentId: string): Promise<TrialFulfillmentDto> => {
  const response = await axiosClient.get<TrialFulfillmentDto>(`${BASE_URL}/trial?i=${trialIntentId}`);
  return response.data;
};
