import React from "react";
import { ListItem } from "@mui/material";
import { useUserContext } from "contexts/UserProvider/UserProvider";
import { ExternalUriMap } from "utils/ExternalUriMap";
import type { CloudLicensingChannel, CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import { inviteUserToCloudSubscription } from "client/api/CloudSubscriptionApi";
import { InviteUserDialog, type InviteUserDialogProps } from "components/Dialogs/InviteUserDialog";
import { ExternalLink } from "components/ExternalLink/ExternalLink";
import { UserGrantDescriptionList } from "components/UserGrantDescription/UserGrantDescriptionList";
import { UserGrantDescriptionTitle } from "components/UserGrantDescription/UserGrantDescriptionTitle";

type InviteUserToCloudSubscriptionProps = Pick<InviteUserDialogProps, "show" | "onCancel"> & {
  cloudSubscription: CloudSubscriptionDto<CloudLicensingChannel>;
  onSubmitComplete: () => Promise<void> | void;
};

const grantValues = ["Everyone (system team)", "Octopus Managers (system team)"] as const;

const GrantsInfo = () => (
  <>
    <UserGrantDescriptionTitle title="Everyone - Cloud Subscription User" />
    <UserGrantDescriptionList>
      <ListItem>Authenticate to this subscription as part of the Everyone group</ListItem>
    </UserGrantDescriptionList>
    <UserGrantDescriptionTitle title="Octopus Managers - Cloud Subscription Owner" />
    <UserGrantDescriptionList>
      <ListItem>Authenticate to this subscription as part of the Octopus Managers group</ListItem>
      <ListItem>Manage billing of this subscription</ListItem>
      <ListItem>Manage configuration of this subscription</ListItem>
      <ListItem>Manage access control for this subscription</ListItem>
    </UserGrantDescriptionList>
  </>
);

export function InviteUserToCloudSubscription(props: InviteUserToCloudSubscriptionProps) {
  const { show, onCancel, onSubmitComplete, cloudSubscription } = props;
  const { userInfo } = useUserContext();

  const onSubmit: InviteUserDialogProps["onSubmit"] = async (email, firstName, lastName, grant) => {
    await inviteUserToCloudSubscription(
      cloudSubscription?.id,
      email,
      `${firstName} ${lastName}`.trim(),
      `${userInfo?.firstName} ${userInfo?.lastName}`.trim(),
      grant
    );
    onSubmitComplete();
  };

  return (
    <InviteUserDialog
      show={show}
      dialogTitle={"Invite user to " + cloudSubscription.friendlyName}
      description={
        <p style={{ margin: 0, marginBottom: "16px", position: "relative", zIndex: "100" }}>
          <ExternalLink href={ExternalUriMap.octopusComDocsCloudInvitingUsersPath}>Visit the docs</ExternalLink> to
          learn about inviting users.
        </p>
      }
      grantLabel={"Team"}
      onCancel={onCancel}
      onSubmit={onSubmit}
      grantsInfo={<GrantsInfo />}
      grantValues={grantValues}
    />
  );
}
