import type { AxiosResponse } from "axios";
import { envConfig } from "utils/envConfig";
import axiosClient from "../axios-client";

const BASE_URL = "ingest";

// See the definition of this format here: https://github.com/serilog/serilog-formatting-compact
export interface SerilogCompactFormatLogEvent {
  "@t": string; // An ISO 8601 timestamp
  "@m": string;
  "@l": SerilogLogLevel;
  "@x"?: string; // Stack trace
  SourceContext: string; //App name (ControlCenter or AdminPortal)
  Environment: string; // Environment (Local, Dev *, PreProd, Production)
}

export type SerilogLogLevel = "Verbose" | "Debug" | "Information" | "Warning" | "Error" | "Fatal";

// There are some types we don't support here - such as symbols or functions.
// There is probably never a useful time to pass them to a logger,
// and knowing this fact makes the logging pipeline easier to implement
export type SerilogPropertyValue = string | number | object | boolean | undefined | null;

export const logError = async (message: string) => {
  await axiosClient.post<unknown, AxiosResponse, SerilogCompactFormatLogEvent>(BASE_URL, {
    "@t": new Date().toISOString(),
    "@m": message,
    "@l": "Error",
    SourceContext: "ControlCenter",
    Environment: envConfig.Environment,
  });
};

export const logErrorWithException = async (
  message: string,
  exception: string,
  customProperties?: Record<string, unknown>
) => {
  await axiosClient.post<
    unknown,
    AxiosResponse,
    SerilogCompactFormatLogEvent & { ExceptionProperties: Record<string, unknown> }
  >(BASE_URL, {
    "@t": new Date().toISOString(),
    "@m": message,
    "@x": exception,
    "@l": "Error",
    SourceContext: "ControlCenter",
    Environment: envConfig.Environment,
    ExceptionProperties: { ...customProperties },
  });
};
