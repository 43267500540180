import React from "react";
import { faServer } from "@fortawesome/free-solid-svg-icons";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { FontAwesomeSvgIcon } from "@cpt/components";
import { useEntity } from "contexts/EntityProvider/EntityProvider";
import type { ServerLicenseDto } from "client/api/ServerLicenseApi";
import { themeTokens } from "components/Theme/theme";

interface ServerLicenseHeaderProps {
  pageTitle: string;
  actions?: React.ReactNode[];
}

export function ServerLicenseHeader(props: ServerLicenseHeaderProps) {
  const { pageTitle, actions } = props;
  const { entity: serverLicense } = useEntity<ServerLicenseDto>();

  if (!serverLicense) return <></>;

  return (
    <>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Box>
              <Stack direction="row" alignItems="center" spacing={1}>
                <FontAwesomeSvgIcon
                  icon={faServer}
                  sx={{ color: themeTokens.color.text.tertiary }}
                ></FontAwesomeSvgIcon>
                <Typography variant={"h5"} color={themeTokens.color.text.primary} fontWeight={600}>
                  {pageTitle}
                </Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid item md={6} display="flex" flexDirection="row" justifyContent="end" alignItems="center">
            <Box>
              <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
                {actions}
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
