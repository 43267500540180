import styled from "@emotion/styled";
import { List } from "@mui/material";

export const UserGrantDescriptionList = styled(List)`
  list-style-type: disc;
  padding: 8px;
  padding-left: 16px;
  .MuiListItem-root {
    display: list-item;
    padding: 0;
  }
`;
