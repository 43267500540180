import React, { createContext, useContext } from "react";
import type { ReactNode } from "react";
import type { FrontendMonitoring } from "FrontendMonitoring";

interface FrontendMonitoringContextProps {
  frontendMonitoring: FrontendMonitoring;
}

const FrontendMonitoringContext = createContext<FrontendMonitoringContextProps | undefined>(undefined);

interface FrontendMonitoringProviderProps {
  children: ReactNode;
  frontendMonitoring: FrontendMonitoring;
}

export const FrontendMonitoringProvider: React.FC<FrontendMonitoringProviderProps> = ({
  children,
  frontendMonitoring,
}) => {
  return (
    <FrontendMonitoringContext.Provider value={{ frontendMonitoring }}>{children}</FrontendMonitoringContext.Provider>
  );
};

export const useFrontendMonitoring = (): FrontendMonitoringContextProps => {
  const context = useContext(FrontendMonitoringContext);
  if (context === undefined) {
    throw new Error("useFrontendMonitoring must be used within a FrontendMonitoringProvider");
  }
  return context;
};
