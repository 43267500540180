import type { Component, ErrorInfo, FunctionComponent, PropsWithChildren, ReactElement } from "react";
import React from "react";
import { SeverityLevel } from "FrontendMonitoring";
import { ErrorBoundary } from "react-error-boundary";
import { useFrontendMonitoring } from "contexts/FrontendMonitoringProvider/FrontendMonitoringProvider";

interface MonitoredErrorBoundaryProps extends PropsWithChildren {
  fallback: ReactElement<unknown, string | FunctionComponent | typeof Component> | null;
  onError?: (error: Error, info: ErrorInfo) => void;
}

export const MonitoredErrorBoundary = ({ children, fallback, onError }: MonitoredErrorBoundaryProps) => {
  const { frontendMonitoring } = useFrontendMonitoring();

  const onErrorHandler = (error: Error, info: ErrorInfo) => {
    frontendMonitoring.trackException(
      { exception: error, severityLevel: SeverityLevel.Error },
      { reactErrorInfo: info }
    );
    onError && onError(error, info);
  };

  return (
    <ErrorBoundary fallback={fallback} onError={onErrorHandler}>
      {children}
    </ErrorBoundary>
  );
};
