import React from "react";
import { Container, Grid, Typography, styled } from "@mui/material";
import { useFeatureFlag } from "contexts/ConfigurationProvider/ConfigurationProvider";
import { useEntity } from "contexts/EntityProvider/EntityProvider";
import useAsyncRequest from "hooks/useAsyncRequest";
import { LoadablePage } from "shared/LoadablePage";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import { CloudLicensingChannelTypesKeys } from "client/api/CloudSubscriptionApi";
import type { GetProductPricingResponse } from "client/api/ProductPricingApi";
import { CloudPricingCatalogProducts, getProductPricing } from "client/api/ProductPricingApi";
import { AddOns } from "components/AddOns/AddOns";
import { FaqTitle, FaqGroupHeading, FaqItem } from "components/Faq";
import { FaqWhatIsAMachine, FaqWhatIsAProject, FaqWhatIsATenant } from "components/Faq/Faqs";
import { Link } from "components/Link/Link";
import { PlansChangedInfoBanner } from "components/PlansChangedInfoBanner/PlansChangedInfoBanner";
import IconCloud from "resources/images/svgs/icon_cloud.svg";
import { EnterprisePricingCard } from "areas/cloudSubscriptions/detail/components/finance/upgrade/EnterprisePricingCard";
import { ProfessionalPricingCard } from "areas/cloudSubscriptions/detail/components/finance/upgrade/ProfessionalPricingCard";
import { StarterPricingCard } from "areas/cloudSubscriptions/detail/components/finance/upgrade/StarterPricingCard";

const GradientBackground = styled("div")`
  position: absolute;
  top: 0px;
  right: 0px;
  height: 300px;
  width: 100%;
  background: linear-gradient(185deg, #00ffa3 -70%, #f7f9fd 23%, #fffeff 100%);
  border-radius: 4px 4px 0px 0px;
`;

const GradientBorder = styled("div")`
  position: absolute;
  top: -1px;
  right: -1px;
  height: 4px;
  background: linear-gradient(90deg, #e6e8ea, #00ffa3);
  width: 100%;
  border-radius: 4px 4px 0px 0px;
`;

const UpgradeContainer = styled("div")`
  background: #fffeff;
  border-radius: 4px;
  border: 1px solid #e6e8ea;
`;

const BackgroundContainer = styled("div")`
  position: relative;
  overflow: visible;
`;

const BodyContent = styled("div")`
  position: relative;
  z-index: 2;
`;

const WeHostText = styled("p")`
  color: #5b6877;
  text-align: center;
`;

const CloudGradientText = styled("span")(
  ({ theme }) => `
  background: linear-gradient(90deg, #12181D 33.23%, #089867 80.56%, #00FFA3 97.62%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
);

const PlanHeaderTitleHeading = styled("h1")(
  ({ theme }) => `
  margin: 0;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: ${theme.typography.fontWeightBold};
  line-height: 1.5;
  text-align: center;
`
);

const PlanSectionHeader = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
`
);

const PlanIconWrapper = styled("div")(
  ({ theme }) => `
  margin-top: 30px;
  margin-bottom: 12px;
`
);

const BannerWrapper = styled("div")`
  padding: 6px 18px;
`;

export type CatalogProduct = GetProductPricingResponse["products"][0] | undefined;
export const PlanTestId = {
  Starter: "starter-plan",
  Professional: "professional-plan",
  Enterprise: "enterprise-plan",
} as const;
export type PlanTestId = (typeof PlanTestId)[keyof typeof PlanTestId];

const PlanHeaderTitle = ({ cloudSubscription }: { cloudSubscription: CloudSubscriptionDto }) => {
  if (cloudSubscription?.licensingChannel.channelType === CloudLicensingChannelTypesKeys.Trial) {
    return (
      <PlanHeaderTitleHeading>
        <CloudGradientText>Upgrade your trial of Octopus Cloud</CloudGradientText>
      </PlanHeaderTitleHeading>
    );
  }

  return (
    <PlanHeaderTitleHeading>
      <CloudGradientText>Change your Octopus Cloud</CloudGradientText> plan
    </PlanHeaderTitleHeading>
  );
};

export function CloudSubscriptionUpgrade() {
  const { entity: cloudSubscription } = useEntity<CloudSubscriptionDto>();
  const { data: catalog, loading, error } = useAsyncRequest(getProductPricing);
  const starterCatalog = catalog?.products.find(
    (item) => item.name.toLowerCase() === CloudPricingCatalogProducts.CloudStarter.toLowerCase()
  );
  const professionalCatalog = catalog?.products.find(
    (item) => item.name.toLowerCase() === CloudPricingCatalogProducts.CloudProfessional.toLowerCase()
  );
  const enterpriseCatalog = catalog?.products.find(
    (item) => item.name.toLowerCase() === CloudPricingCatalogProducts.CloudEnterprise.toLowerCase()
  );
  const useZuoraCheckout = useFeatureFlag("ZuoraCheckout");

  // ensure valid channel
  const validChannels: string[] = useZuoraCheckout
    ? [CloudLicensingChannelTypesKeys.Trial]
    : [CloudLicensingChannelTypesKeys.Stripe, CloudLicensingChannelTypesKeys.Trial];
  if (!cloudSubscription || !validChannels.includes(cloudSubscription.licensingChannel.channelType))
    return (
      <Typography>
        Please email{" "}
        <Link target="_blank" rel="noopener" href="mailto:sales@octopus.com">
          sales@octopus.com
        </Link>{" "}
        to make changes to your license
      </Typography>
    );

  return (
    <>
      <LoadablePage loading={loading} error={error}>
        <UpgradeContainer>
          <BackgroundContainer>
            <GradientBorder />
            <GradientBackground />
          </BackgroundContainer>
          <BodyContent>
            <PlanSectionHeader>
              <PlanIconWrapper>
                <img src={IconCloud} width="47px" height={"33px"} alt="cloud" />
              </PlanIconWrapper>
              <WeHostText>We host, no technical setup required</WeHostText>
              <PlanHeaderTitle cloudSubscription={cloudSubscription} />
            </PlanSectionHeader>
            <BannerWrapper>
              <PlansChangedInfoBanner />
            </BannerWrapper>
            <Grid container spacing={2} padding={2} sx={{ paddingBottom: "60px" }}>
              <Grid item xs={12} md={4}>
                <StarterPricingCard catalog={starterCatalog} />
              </Grid>
              <Grid item xs={12} md={4}>
                <ProfessionalPricingCard catalog={professionalCatalog} />
              </Grid>
              <Grid item xs={12} md={4}>
                <EnterprisePricingCard catalog={enterpriseCatalog} />
              </Grid>
            </Grid>
            <Grid container padding={0}>
              <Grid item xs={12}>
                <AddOns />
              </Grid>
            </Grid>
            <Grid container spacing={2} padding={2}>
              <Grid item xs={12}>
                <FrequentlyAskedQuestions />
              </Grid>
            </Grid>
          </BodyContent>
        </UpgradeContainer>
      </LoadablePage>
    </>
  );
}

const FrequentlyAskedQuestions = () => {
  return (
    <Container sx={{ paddingTop: "40px", paddingBottom: "20px", background: "#FFFEFF" }}>
      <FaqTitle id="faq">Frequently asked questions</FaqTitle>
      <FaqGroupHeading>Marketplaces</FaqGroupHeading>
      <FaqItem title={"Can I purchase Octopus Cloud on the Azure marketplace?"}>
        Yes! Please reach out to{" "}
        <Link target={"_blank"} rel={"noopener"} href={"mailto:sales@octopus.com"} fontWeight={"inherit"}>
          sales@octopus.com
        </Link>{" "}
        and we can walk you through the process.
      </FaqItem>
      <FaqGroupHeading>Feature questions</FaqGroupHeading>
      <FaqWhatIsAProject />
      <FaqWhatIsATenant />
      <FaqWhatIsAMachine />
    </Container>
  );
};
