import React from "react";
import { ListItem } from "@mui/material";
import { useUserContext } from "contexts/UserProvider/UserProvider";
import { ExternalUriMap } from "utils/ExternalUriMap";
import { inviteUserToSubscriptionGroup } from "client/api/SubscriptionGroupApi";
import type { InviteUserDialogProps } from "components/Dialogs/InviteUserDialog";
import { InviteUserDialog } from "components/Dialogs/InviteUserDialog";
import { ExternalLink } from "components/ExternalLink/ExternalLink";
import { UserGrantDescriptionList } from "components/UserGrantDescription/UserGrantDescriptionList";
import { UserGrantDescriptionTitle } from "components/UserGrantDescription/UserGrantDescriptionTitle";

type InviteUserToSubscriptionGroupProps = Pick<InviteUserDialogProps, "show" | "onCancel"> & {
  subscriptionGroupId: string;
  onSubmitComplete: () => Promise<void> | void;
};

const grantValues = [
  {
    label: "Administrator",
    value: "Admin",
  },
  { label: "Technical Manager", value: "Tech" },
  { label: "Billing Manager", value: "Billing" },
] as const;

const GrantsInfo = () => {
  return (
    <>
      <UserGrantDescriptionTitle title="Administrator" />
      <UserGrantDescriptionList>
        <ListItem>
          Authenticate to any subscription in this subscription group as part of the Octopus Managers group
        </ListItem>
        <ListItem>Manage billing of any subscription in this subscription group</ListItem>
        <ListItem>Manage configuration of any subscription in this subscription group</ListItem>
        <ListItem>Manage access control for any subscription in this subscription group</ListItem>
        <ListItem>Rename or delete this subscription group</ListItem>
        <ListItem>Manage access control for this subscription group</ListItem>
      </UserGrantDescriptionList>
      <UserGrantDescriptionTitle title="Technical Manager" />
      <UserGrantDescriptionList>
        <ListItem>
          Authenticate to any subscription in this subscription group as part of the Octopus Managers group
        </ListItem>
        <ListItem>Manage configuration of any subscription in this subscription group</ListItem>
        <ListItem>Manage access control for any subscription in this subscription group</ListItem>
        <ListItem>Rename or delete this subscription group</ListItem>
        <ListItem>Manage access control for this subscription group</ListItem>
      </UserGrantDescriptionList>
      <UserGrantDescriptionTitle title="Billing Manager" />
      <UserGrantDescriptionList>
        <ListItem>Manage billing of any subscription in this subscription group</ListItem>
      </UserGrantDescriptionList>
    </>
  );
};

export function InviteUserToSubscriptionGroup(props: InviteUserToSubscriptionGroupProps) {
  const { show, onCancel, onSubmitComplete, subscriptionGroupId } = props;
  const { userInfo } = useUserContext();

  const onSubmit: InviteUserDialogProps["onSubmit"] = async (email, firstName, lastName, grant) => {
    await inviteUserToSubscriptionGroup(
      subscriptionGroupId,
      email,
      `${firstName} ${lastName}`.trim(),
      `${userInfo?.firstName} ${userInfo?.lastName}`.trim(),
      grant
    );
    onSubmitComplete();
  };

  return (
    <InviteUserDialog
      show={show}
      dialogTitle={"Invite a user to this subscription group"}
      description={
        <p style={{ margin: 0, marginBottom: "16px", position: "relative", zIndex: "100" }}>
          <ExternalLink href={ExternalUriMap.octopusComDocsAccessControlPath}>Visit the docs</ExternalLink> to learn
          about inviting users.
        </p>
      }
      onCancel={onCancel}
      onSubmit={onSubmit}
      grantsInfo={<GrantsInfo />}
      grantValues={grantValues}
    />
  );
}
