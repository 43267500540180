// Should be kept up to date with Commercial.Billing.Persistence.Models.Permission.cs
export const RbacPermissions = {
  CloudSubscription: {
    AuthenticateToInstanceAsUser: "CloudSubscription.AuthenticateToInstanceAsUser",
    AuthenticateToInstanceAsAdmin: "CloudSubscription.AuthenticateToInstanceAsAdmin",
    ViewConfiguration: "CloudSubscription.ViewConfiguration",
    ManageConfiguration: "CloudSubscription.ManageConfiguration",
    ViewBilling: "CloudSubscription.ViewBilling",
    ManageBilling: "CloudSubscription.ManageBilling",
    ViewAccessControl: "CloudSubscription.ViewAccessControl",
    ManageAccessControl: "CloudSubscription.ManageAccessControl",
  },
  SubscriptionGroup: {
    Rename: "SubscriptionGroup.Rename",
    Delete: "SubscriptionGroup.Delete",
    ViewAccessControl: "SubscriptionGroup.ViewAccessControl",
    ManageAccessControl: "SubscriptionGroup.ManageAccessControl",
  },
  ServerLicense: {
    ViewLicenseKeyXml: "ServerLicense.ViewLicenseKeyXml",
    ViewBilling: "ServerLicense.ViewBilling",
    ManageBilling: "ServerLicense.ManageBilling",
    ViewAccessControl: "ServerLicense.ViewAccessControl",
    ManageAccessControl: "ServerLicense.ManageAccessControl",
  },
} as const;

type CloudSubscriptionPermissions =
  (typeof RbacPermissions.CloudSubscription)[keyof typeof RbacPermissions.CloudSubscription];
type SubscriptionGroupPermissions =
  (typeof RbacPermissions.SubscriptionGroup)[keyof typeof RbacPermissions.SubscriptionGroup];
type ServerLicensePermissions = (typeof RbacPermissions.ServerLicense)[keyof typeof RbacPermissions.ServerLicense];

export type RbacPermission = CloudSubscriptionPermissions | SubscriptionGroupPermissions | ServerLicensePermissions;
