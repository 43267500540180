import axios from "axios";
import { setupAppInsights } from "setupAppInsights";
import { logErrorWithException } from "client/api/LoggingApi";

export enum SeverityLevel {
  Verbose = 0,
  Information = 1,
  Warning = 2,
  Error = 3,
  Critical = 4,
}

interface ExceptionTelemetry {
  id?: string;
  exception?: Error;
  severityLevel: SeverityLevel;
}

interface PageViewTelemetry {
  /** Name of the pageview. Defaults to the document title. */
  name?: string;
  /** A relative or absolute URL that identifies the page or other item. Defaults to the window location. */
  uri?: string;
  /** The URL of the source page where current page is loaded from. */
  refUri?: string;
  /** Page Type string. Describes how you classify this page, e.g. errorPage, formPage, etc. */
  pageType?: string;
  /** Is the user logged in? */
  isLoggedIn?: boolean;
  /** Time first page view is triggered. */
  startTime?: Date;
}

interface TraceTelemetry {
  message: string;
  severityLevel: SeverityLevel;
}

export class FrontendMonitoring {
  private _appInsights;
  private _eventHandlers: {
    onError?: void;
    onUnhandledRejection?: void;
  } = {};

  constructor() {
    this._appInsights = this.setupAppInsights();
  }

  private setupAppInsights = () => {
    const appInsights = setupAppInsights();
    this.addEventHandlers();
    return appInsights;
  };

  private addEventHandlers = () => {
    this._eventHandlers.onError = window.addEventListener("error", (event) => {
      const { filename, lineno, colno, error } = event;
      error.message = `Uncaught Error: ${error.message}`;
      this.trackException(
        { exception: error, severityLevel: SeverityLevel.Error },
        {
          url: filename,
          errorSrc: `window.onerror@${filename}:${lineno}:${colno}`,
          lineNumber: lineno,
          columnNumber: colno,
        }
      );
    });

    this._eventHandlers.onUnhandledRejection = window.addEventListener("unhandledrejection", (event) => {
      const url = axios.isAxiosError(event.reason) ? event.reason.config.url : undefined;
      this.trackException(
        {
          exception: new Error(`Unhandled Rejection: ${event.reason.message}, url: ${url}`),
          severityLevel: SeverityLevel.Error,
        },
        {
          typeName: "UnhandledRejection",
          url,
        }
      );
    });
  };

  private removeEventHandlers = () => {
    this._eventHandlers?.onError && window.removeEventListener("error", this._eventHandlers.onError);
    this._eventHandlers?.onUnhandledRejection &&
      window.removeEventListener("unhandledrejection", this._eventHandlers.onUnhandledRejection);
  };

  /**
   * Log an exception that you have caught.
   * @param exception
   * @param customProperties
   */
  public trackException = (exception: ExceptionTelemetry, customProperties?: Record<string, unknown>) => {
    this._appInsights?.trackException(exception, customProperties);

    const message = exception.exception?.message ?? "";
    const errorString = exception.exception?.stack ?? exception.exception?.toString() ?? "";
    logErrorWithException(message, errorString, customProperties);
  };

  /**
   * Log a user action or other occurrence.
   * @param event
   * @param event.name
   * @param customProperties
   */
  public trackEvent = (event: { name: string }, customProperties?: Record<string, unknown>) => {
    this._appInsights?.trackEvent(event, customProperties);
  };

  /**
   * Logs that a page, or similar container was displayed to the user.
   * @param pageView
   */
  public trackPageView = (pageView?: PageViewTelemetry) => {
    this._appInsights?.trackPageView(pageView);
  };

  /**
   * Log a diagnostic scenario such as entering or leaving a function
   * @param trace
   * @param customProperties
   */
  public trackTrace = (trace: TraceTelemetry, customProperties?: Record<string, unknown>) => {
    this._appInsights?.trackTrace(trace, customProperties);
  };
}
