import React from "react";
import { ListItem } from "@mui/material";
import { useUserContext } from "contexts/UserProvider/UserProvider";
import { ExternalUriMap } from "utils/ExternalUriMap";
import type { ServerLicenseDto } from "client/api/ServerLicenseApi";
import { inviteUserToServerLicense } from "client/api/ServerLicenseApi";
import type { InviteUserDialogProps } from "components/Dialogs/InviteUserDialog";
import { InviteUserDialog } from "components/Dialogs/InviteUserDialog";
import { ExternalLink } from "components/ExternalLink/ExternalLink";
import { UserGrantDescriptionList } from "components/UserGrantDescription/UserGrantDescriptionList";
import { UserGrantDescriptionTitle } from "components/UserGrantDescription/UserGrantDescriptionTitle";

type InviteUserToServerLicenseProps = Pick<InviteUserDialogProps, "show" | "onCancel"> & {
  serverLicense: ServerLicenseDto;
  onSubmitComplete: () => Promise<void> | void;
};

const grantValues = [
  { label: "Server License Viewer", value: "User" },
  {
    label: "Server License Owner",
    value: "Admin",
  },
] as const;

const GrantsInfo = () => (
  <>
    <UserGrantDescriptionTitle title={"Server License Viewer"} />
    <UserGrantDescriptionList>
      <ListItem>View this server license</ListItem>
    </UserGrantDescriptionList>
    <UserGrantDescriptionTitle title="Server License Owner" />
    <UserGrantDescriptionList>
      <ListItem>View this server license</ListItem>
      <ListItem>Manage billing of this server license</ListItem>
      <ListItem>Manage access control for this server license</ListItem>
    </UserGrantDescriptionList>
  </>
);

export function InviteUserToServerLicense(props: InviteUserToServerLicenseProps) {
  const { show, onCancel, onSubmitComplete, serverLicense } = props;
  const { userInfo } = useUserContext();

  const onSubmit: InviteUserDialogProps["onSubmit"] = async (email, firstName, lastName, grant) => {
    await inviteUserToServerLicense(
      serverLicense?.id,
      email,
      `${firstName} ${lastName}`.trim(),
      `${userInfo?.firstName} ${userInfo?.lastName}`.trim(),
      grant
    );
    onSubmitComplete();
  };

  const serverName = serverLicense.friendlyName ?? "server";

  return (
    <InviteUserDialog
      show={show}
      dialogTitle={`Invite user to ${serverName}`}
      description={
        <p style={{ margin: 0, marginBottom: "16px", position: "relative", zIndex: "100" }}>
          <ExternalLink href={ExternalUriMap.octopusComDocsAccessControlPath}>Visit the docs</ExternalLink> to learn
          about inviting users.
        </p>
      }
      onCancel={onCancel}
      onSubmit={onSubmit}
      grantsInfo={<GrantsInfo />}
      grantValues={grantValues}
    />
  );
}
