import React from "react";
import { Typography } from "@mui/material";

interface UserGrantDescriptionTitleProps {
  title: string;
}

export const UserGrantDescriptionTitle = ({ title }: UserGrantDescriptionTitleProps) => {
  return (
    <Typography color={"inherit"}>
      <strong>{title}</strong>
    </Typography>
  );
};
