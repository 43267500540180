import { envConfig } from "./envConfig";

const octopusComRoot = envConfig.OctopusComDomain;

// Pricing
const octopusComPricingRootPath = `${octopusComRoot}/pricing`;
const octopusComPricingOverviewPath = `${octopusComPricingRootPath}/overview`;
const octopusComPricingFeaturesPath = `${octopusComPricingOverviewPath}#features`;
const octopusComPricingFaqPath = `${octopusComPricingRootPath}/faq`;
const octopusComPricingFaqCloudPath = `${octopusComPricingFaqPath}#octopus-cloud-section`;
const octopusComPricingFaqCloudPricingPath = `${octopusComPricingFaqPath}#what-is-the-pricing-for-octopus-cloud`;
const octopusComPricingFaqMachinesPath = `${octopusComPricingFaqPath}#what-is-a-machine`;

// Docs
const octopusComDocsRootPath = `${octopusComRoot}/docs`;
const octopusComDocsInstallationPath = `${octopusComDocsRootPath}/installation`;
const octopusComDocsLinuxInstallationPath = `${octopusComDocsInstallationPath}/octopus-server-linux-container`;

const octopusComDocsProjectsPath = `${octopusComDocsRootPath}/projects`;
const octopusComDocsDeploymentProcessPath = `${octopusComDocsProjectsPath}/deployment-process`;

const octopusComDocsAdministrationPath = `${octopusComDocsRootPath}/administration`;
const octopusComDocsLicenseManagementPath = `${octopusComDocsAdministrationPath}/managing-licenses`;
const octopusComDocsLicenseManagementCommunityPath = `${octopusComDocsLicenseManagementPath}/community`;

const octopusComDocsGettingStartedPath = `${octopusComDocsRootPath}/getting-started`;
const octopusComDocsManagingOctopusSubscriptionsPath = `${octopusComDocsGettingStartedPath}/managing-octopus-subscriptions`;

const octopusComDocsFirstDeploymentPath = `${octopusComDocsGettingStartedPath}/first-deployment`;
const octopusComDocsFirstKubernetesDeploymentPath = `${octopusComDocsRootPath}/kubernetes/tutorials`;

const octopusComDocsBestPractices = `${octopusComDocsGettingStartedPath}/best-practices`;
const octopusComDocsInstallationGuidelinesPath = `${octopusComDocsBestPractices}/installation-guidelines`;

const octopusComDocsRetentionPolicyPath = `${octopusComDocsRootPath}/retention-policy`;

const octopusComDocsInfrastructureDeploymentTargetsPath = `${octopusComDocsRootPath}/infrastructure/deployment-targets`;

const octopusComDocsCloudPath = `${octopusComDocsRootPath}/octopus-cloud`;
const octopusComDocsCloudInvitingUsersPath = `${octopusComDocsCloudPath}/getting-started-with-cloud#inviting-octopus-cloud-users`;

const octopusComDocsAccessControlPath = `${octopusComDocsGettingStartedPath}/managing-octopus-subscriptions#access-control`;

// Legal
const octopusComAcceptableUsagePolicyPath = `${octopusComDocsGettingStartedPath}/legal/acceptable-usage`;

// Downloads
const octopusComDownloadsRootPath = `${octopusComRoot}/downloads`;
const octopusComDownloadsServerPath = `${octopusComDownloadsRootPath}/server`;

// Support
const octopusComSupportRootPath = `${octopusComRoot}/support`;
const octopusComSupportFilesRootPath = `${octopusComRoot}/support-files`;

// Profile
const octopusComProfilePath = `${octopusComRoot}/profile`;

// Sign Out
const octopusComSignOutPath = `${octopusComRoot}/signout`;

// Control Center V1
const octopusComControlCenterV1RootPath = `${octopusComRoot}/control-center`;

// Azure Marketplace
const azureMarketPlaceRoot = "https://azuremarketplace.microsoft.com";
const azureMarketPlaceOctopusDeployOverviewPath = `${azureMarketPlaceRoot}/en-us/marketplace/apps/octopusdeployptyltd1664464418055.octopus_cloud_saas_offer?tab=overview`;

// Helm Chart
const githubHelmChartRootPath = `https://github.com/OctopusDeploy/helm-charts`;
const githubHelmChartOctopusRootPath = `${githubHelmChartRootPath}/tree/main/charts/octopus-deploy`;
const githubHelmChartOctopusIngressDocsPath = `${githubHelmChartOctopusRootPath}#ingress`;

const octopusCommunitySlackInvitePath = "https://oc.to/community-slack";

export const ExternalUriMap = {
  octopusComRoot,
  octopusComPricingRootPath,
  octopusComPricingFeaturesPath,
  octopusComPricingFaqPath,
  octopusComPricingFaqCloudPath,
  octopusComPricingFaqCloudPricingPath,
  octopusComPricingFaqMachinesPath,
  octopusComDocsRootPath,
  octopusComDocsInstallationPath,
  octopusComDocsLinuxInstallationPath,
  octopusComDocsProjectsPath,
  octopusComDocsDeploymentProcessPath,
  octopusComDocsAdministrationPath,
  octopusComDocsLicenseManagementPath,
  octopusComDocsLicenseManagementCommunityPath,
  octopusComDocsGettingStartedPath,
  octopusComDocsManagingOctopusSubscriptionsPath,
  octopusComDocsFirstDeploymentPath,
  octopusComDocsFirstKubernetesDeploymentPath,
  octopusComDocsBestPractices,
  octopusComDocsInstallationGuidelinesPath,
  octopusComDocsRetentionPolicyPath,
  octopusComDownloadsRootPath,
  octopusComDownloadsServerPath,
  octopusComSupportRootPath,
  octopusComSupportFilesRootPath,
  octopusComAcceptableUsagePolicyPath,
  octopusComControlCenterV1RootPath,
  octopusComDocsInfrastructureDeploymentTargetsPath,
  octopusComDocsCloudPath,
  octopusComDocsCloudInvitingUsersPath,
  octopusComProfilePath,
  octopusComSignOutPath,
  azureMarketPlaceOctopusDeployOverviewPath,
  githubHelmChartRootPath,
  githubHelmChartOctopusRootPath,
  githubHelmChartOctopusIngressDocsPath,
  octopusCommunitySlackInvitePath,
  octopusComDocsAccessControlPath,
};
