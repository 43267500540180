import React from "react";
import { Typography, Container, Link, Grid, styled } from "@mui/material";
import { useFeatureFlag } from "contexts/ConfigurationProvider/ConfigurationProvider";
import { useEntity } from "contexts/EntityProvider/EntityProvider";
import useAsyncRequest from "hooks/useAsyncRequest";
import { LoadablePage } from "shared/LoadablePage";
import type { GetProductPricingResponse } from "client/api/ProductPricingApi";
import { ServerPricingCatalogProducts, getProductPricing } from "client/api/ProductPricingApi";
import type { ServerLicenseDto } from "client/api/ServerLicenseApi";
import { ServerLicensingChannelTypesKeys } from "client/api/ServerLicenseApi";
import { AddOns } from "components/AddOns/AddOns";
import { FaqTitle, FaqGroupHeading } from "components/Faq";
import { FaqWhatIsAMachine, FaqWhatIsAProject, FaqWhatIsATenant } from "components/Faq/Faqs";
import { PlansChangedInfoBanner } from "components/PlansChangedInfoBanner/PlansChangedInfoBanner";
import IconServer from "resources/images/svgs/icon_server.svg";
import { ServerEnterprisePricingCard } from "areas/serverLicenses/detail/components/upgrade/ServerEnterprisePricingCard";
import { ServerProfessionalPricingCard } from "areas/serverLicenses/detail/components/upgrade/ServerProfessionalPricingCard";
import { ServerStarterPricingCard } from "areas/serverLicenses/detail/components/upgrade/ServerStarterPricingCard";

const GradientBackground = styled("div")`
  position: absolute;
  top: 0px;
  right: 0px;
  height: 300px;
  width: 100%;
  background: linear-gradient(185deg, #1fc0ff -100%, #f7f9fd 23%, #fffeff);
  border-radius: 4px 4px 0px 0px;
`;

const GradientBorder = styled("div")`
  position: absolute;
  top: -1px;
  right: -1px;
  height: 4px;
  background: linear-gradient(90deg, #e6e8ea, #1fc0ff);
  width: 100%;
  border-radius: 4px 4px 0px 0px;
`;

const UpgradeContainer = styled("div")`
  background: #fffeff;
  border-radius: 4px;
  border: 1px solid #e6e8ea;
`;

const BackgroundContainer = styled("div")`
  position: relative;
  overflow: visible;
`;

const BodyContent = styled("div")`
  position: relative;
  z-index: 2;
`;

const PlanHeaderTagLine = styled("p")`
  color: #5b6877;
  text-align: center;
`;

const ServerGradientText = styled("span")(
  ({ theme }) => `
  background: linear-gradient(90deg, #12181d 33.23%, #1fc0ff 97.62%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
);

const PlanHeaderTitleHeading = styled("h1")(
  ({ theme }) => `
  margin: 0;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: ${theme.typography.fontWeightBold};
  line-height: 1.5;
  text-align: center;
`
);

const PlanSectionHeader = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
`
);

const PlanIconWrapper = styled("div")(
  ({ theme }) => `
  margin-top: 30px;
  margin-bottom: 12px;
`
);

const BannerWrapper = styled("div")`
  padding: 6px 18px;
`;

export type CatalogProduct = GetProductPricingResponse["products"][0] | undefined;
export const PlanTestId = {
  Starter: "starter-plan",
  Professional: "professional-plan",
  Enterprise: "enterprise-plan",
} as const;
export type PlanTestId = (typeof PlanTestId)[keyof typeof PlanTestId];

const PlanHeaderTitle = ({ serverLicense }: { serverLicense: ServerLicenseDto }) => {
  if (serverLicense?.licensingChannel.channelType === ServerLicensingChannelTypesKeys.Trial) {
    return (
      <PlanHeaderTitleHeading>
        <ServerGradientText>Upgrade your trial of Octopus Server</ServerGradientText>
      </PlanHeaderTitleHeading>
    );
  }

  return (
    <PlanHeaderTitleHeading>
      <ServerGradientText>Change your Octopus Server</ServerGradientText> plan
    </PlanHeaderTitleHeading>
  );
};

export function ServerLicenseUpgrade() {
  const { entity: serverLicense } = useEntity<ServerLicenseDto>();
  const { data: catalog, loading, error } = useAsyncRequest(getProductPricing);
  const starterCatalog = catalog?.products.find(
    (item) => item.name.toLowerCase() === ServerPricingCatalogProducts.ServerStarter.toLowerCase()
  );
  const professionalCatalog = catalog?.products.find(
    (item) => item.name.toLowerCase() === ServerPricingCatalogProducts.ServerProfessional.toLowerCase()
  );
  const enterpriseCatalog = catalog?.products.find(
    (item) => item.name.toLowerCase() === ServerPricingCatalogProducts.ServerEnterprise.toLowerCase()
  );
  const useZuoraCheckout = useFeatureFlag("ZuoraCheckout");

  // ensure valid channel
  const validChannels: string[] = useZuoraCheckout
    ? [ServerLicensingChannelTypesKeys.Trial]
    : [ServerLicensingChannelTypesKeys.Stripe, ServerLicensingChannelTypesKeys.Trial];
  if (!serverLicense || !validChannels.includes(serverLicense.licensingChannel.channelType))
    return (
      <Typography>
        Please email{" "}
        <Link target="_blank" rel="noopener" href="mailto:sales@octopus.com">
          sales@octopus.com
        </Link>{" "}
        to make changes to your license
      </Typography>
    );

  return (
    <>
      <LoadablePage loading={loading} error={error}>
        <UpgradeContainer>
          <BackgroundContainer>
            <GradientBorder />
            <GradientBackground />
          </BackgroundContainer>
          <BodyContent>
            <PlanSectionHeader>
              <PlanIconWrapper>
                <img src={IconServer} width="47px" height={"33px"} alt="server" />
              </PlanIconWrapper>
              <PlanHeaderTagLine>Keep using Octopus on your own setup</PlanHeaderTagLine>
              <PlanHeaderTitle serverLicense={serverLicense} />
            </PlanSectionHeader>
            <BannerWrapper>
              <PlansChangedInfoBanner />
            </BannerWrapper>
            <Grid container spacing={2} padding={2} sx={{ paddingBottom: "60px" }}>
              <Grid item xs={12} md={4}>
                <ServerStarterPricingCard catalog={starterCatalog} />
              </Grid>
              <Grid item xs={12} md={4}>
                <ServerProfessionalPricingCard catalog={professionalCatalog} />
              </Grid>
              <Grid item xs={12} md={4}>
                <ServerEnterprisePricingCard catalog={enterpriseCatalog} />
              </Grid>
            </Grid>
            <Grid container padding={0}>
              <Grid item xs={12}>
                <AddOns />
              </Grid>
            </Grid>
            <Grid container spacing={2} padding={2}>
              <Grid item xs={12}>
                <FrequentlyAskedQuestions />
              </Grid>
            </Grid>
          </BodyContent>
        </UpgradeContainer>
      </LoadablePage>
    </>
  );
}

const FrequentlyAskedQuestions = () => {
  return (
    <Container sx={{ paddingTop: "40px", paddingBottom: "20px", background: "#FFFEFF" }}>
      <FaqTitle id="faq">Frequently asked questions</FaqTitle>
      <FaqGroupHeading>Feature questions</FaqGroupHeading>
      <FaqWhatIsAProject />
      <FaqWhatIsATenant />
      <FaqWhatIsAMachine />
    </Container>
  );
};
